function filterMethod(allTabList, qrcode) {
  return allTabList.filter(item => item.id === qrcode);
}

function filterTabLinks(allTabList, tabLinks) {
  return tabLinks.map(item => {
    for (const tab of allTabList) {
      if (tab.id === item.id) {
        return tab;
      }
    }
  });
}

function filterExtr(allTabList) {
  return allTabList.filter(
    item =>
      item.type !== "WECHAT_WEBSITE" &&
      item.type !== "WECHAT_OFFICIAL" &&
      item.type !== "WECHAT_RZKC" &&
      item.type !== "WECHAT_ENTERPRISE" &&
      item.type !== "DING_TALK" &&
      item.type !== "LARK" &&
      item.type !== "WebEXP"
  );
}

function existSpecialType(source) {
  if (
    source.type === "WECHAT_WEBSITE" ||
    source.type === "WECHAT_OFFICIAL" ||
    source.type === "WECHAT_RZKC" ||
    source.type === "WECHAT_ENTERPRISE" ||
    source.type === "DING_TALK" ||
    source.type === "LARK"
  ) {
    return true;
  }
}

function isExistSpecialType(allTabList) {
  return allTabList.find(
    item =>
      item.type === "WECHAT_WEBSITE" ||
      item.type === "WECHAT_OFFICIAL" ||
      item.type === "WECHAT_RZKC" ||
      item.type === "WECHAT_ENTERPRISE" ||
      item.type === "DING_TALK" ||
      item.type === "LARK"
  );
}

function filterTypeItem(allTabList, type) {
  if (!allTabList) return;
  return allTabList.find(item => item.type === type);
}

function filterIdentitySource(source_list) {
  let sources = source_list.filter(
    item =>
      item.identity_source &&
      item.type !== "WECHAT_ENTERPRISE" &&
      item.type !== "WECHAT_RZKC" &&
      item.type !== "WECHAT_WEBSITE" &&
      item.type !== "WECHAT_OFFICIAL" &&
      item.type !== "WEIBO" &&
      item.type !== "LARK" &&
      item.type !== "DING_TALK" &&
      item.type !== "LARK" &&
      item.type !== "WebEXP"
  );
  return sources;
}

function filterCommonLogins(allTabList) {
  return allTabList.filter(
    item =>
      item.type !== "WECHAT_ENTERPRISE" &&
      item.type !== "WECHAT_RZKC" &&
      item.type !== "WECHAT_WEBSITE" &&
      item.type !== "WECHAT_OFFICIAL" &&
      item.type !== "WEIBO" &&
      item.type !== "LARK" &&
      item.type !== "DING_TALK" &&
      item.type !== "LARK" &&
      item.type !== "WebEXP" &&
      item.type !== "WEBVPN"
  );
}

function WechatEnterpriseLogins(allTabList) {
  return allTabList.filter(item => item.type == "WECHAT_ENTERPRISE");
}

function WechatLogins(allTabList) {
  return allTabList.filter(
    item =>
      (item.type == "WECHAT_ENTERPRISE" && item.wechat_qrcode == true) ||
      item.type == "WECHAT_RZKC"
  );
}

function DingTalkLogins(allTabList) {
  return allTabList.filter(item => item.type == "DING_TALK");
}

function LarkLogins(allTabList) {
  return allTabList.filter(item => item.type == "LARK");
}

export {
  filterMethod,
  filterTabLinks,
  filterExtr,
  existSpecialType,
  isExistSpecialType,
  filterTypeItem,
  filterIdentitySource,
  filterCommonLogins,
  WechatEnterpriseLogins,
  WechatLogins,
  DingTalkLogins,
  LarkLogins
};
